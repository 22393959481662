import React, { useContext, useEffect, useState } from 'react'
import {
  Typography,
  Paper,
  makeStyles,
  Select,
  FormControl,
  MenuItem,
  InputLabel,
  IconButton,
  TextField,
  Button,
  TableSortLabel,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  Checkbox,
} from '@material-ui/core'
import { Refresh, Add, InsertDriveFile } from '@material-ui/icons'
import { useToasts } from 'react-toast-notifications'

import lodash from 'lodash'

import api from '../dataProvider'
import { IUserParams } from '../types'
import { processName } from '../util'
import { UserContext } from '../contexts'
import Dropzone from 'react-dropzone'

const useStyles = makeStyles((theme) => ({
  filter: {
    padding: 20,
  },
  rolesFormControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  groupsFormControl: {
    margin: theme.spacing(1),
    minWidth: 240,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 130,
  },
  resetButton: {
    margin: theme.spacing(1),
  },
}))

export default function Users() {
  const currentUser = useContext(UserContext)

  const classes = useStyles()
  const { addToast } = useToasts()

  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(25)
  const [users, setUsers] = useState([] as Array<any>)
  const [total, setTotal] = useState(0)
  const [sortKey, setSortKey] = useState('createDesc' as 'createAsc' | 'createDesc' | 'accessDesc' | 'accessAsc')
  const [role, setRole] = useState('')
  const [createFromVal, setCreateFromVal] = useState('')
  const [createToVal, setCreateToVal] = useState('')
  const [searchKey, setSearchKey] = useState('')
  const [groupVal, setGroupVal] = useState('')
  const [groups, setGroups] = useState([])
  const [changeGroup, setChangeGroup] = useState(false)
  const [groupToChange, setGroupToChange] = useState('')
  const [idsToChange, setIdsToChange] = useState([])
  const [refresh, setRefresh] = useState(true)
  const [openMassRegisterDialog, setOpenMassRegisterDialog] = useState(false)

  const handleChangeLimit = (event: any) => {
    setLimit(parseInt(event.target.value))
    setPage(1)
  }

  const handleReset = () => {
    setRole('')
    setCreateFromVal('')
    setCreateToVal('')
    setSearchKey('')
    setGroupVal('')
  }

  const handleChangeRole = async (user: any, value: string) => {
    try {
      const resUser = await api.updateUser({ id: user._id, role: value })
      setUsers(
        users.map((user: any) => {
          if (user._id === resUser._id) {
            return resUser
          } else {
            return user
          }
        }),
      )
      addToast('Role changed successfully', {
        appearance: 'success',
      })
    } catch (err) {
      console.log(err)
      addToast(`Failed to change role: ${err.message}`, {
        appearance: 'error',
      })
    }
  }

  const handleCreatedSortKeyChange = () => {
    setSortKey(sortKey === 'createDesc' ? 'createAsc' : 'createDesc')
  }

  const handleAccessSortKeyChange = () => {
    setSortKey(sortKey === 'accessDesc' ? 'accessAsc' : 'accessDesc')
  }

  const changeUsersGroup = async () => {
    try {
      await api.updateUsersGroup(idsToChange, groupToChange)
      setChangeGroup(false)
      setIdsToChange([])
      setGroupToChange('')
      setRefresh(!refresh)
      addToast('Group changed successfully', {
        appearance: 'success',
      })
    } catch (err) {
      console.log(err)
      addToast(`Failed to change group: ${err.message}`, {
        appearance: 'error',
      })
    }
  }

  useEffect(() => {
    api.getGroups().then((res) => setGroups(res))
  })

  useEffect(() => {
    if (searchKey === '') {
      ;(document.querySelector('#user-search') as any).value = '' // to set the search field as blank
    }
  }, [searchKey])

  useEffect(() => {
    const userParams: IUserParams = {
      role: role,
      createdDateStart: createFromVal
        ? new Date(new Date(createFromVal).getTime() - 8*60*60000).toISOString()
        : '',
      createdDateEnd: createToVal ? new Date(new Date(createToVal).getTime() - 8*60*60000).toISOString() : '',
      searchKey: searchKey,
      group: groupVal,
    }
    const params: IUserParams = {}
    Object.keys(userParams).forEach((param) => {
      if ((userParams as any)[param])
        (params as any)[param] = (userParams as any)[param]
    })
    api
      .getUsers({ page: page, limit, sortKey, ...params })
      .then((data) => {
        setUsers(data.docs)
        setTotal(data.totalDocs)
      })
      .catch((err) => {
        console.log(err)
        addToast(`Failed to fetch users: ${err.message}`, {
          appearance: 'error',
          autoDismiss: false,
        })
      })
  }, [
    addToast,
    limit,
    page,
    sortKey,
    role,
    createFromVal,
    createToVal,
    searchKey,
    groupVal,
    refresh,
  ])

  const cols = [
    'Name',
    'Email',
    'Role',
    'Group',
    <TableSortLabel active={['createAsc', 'createDesc'].includes(sortKey)} direction={sortKey === 'createAsc' ? 'asc' : 'desc'} onClick={handleCreatedSortKeyChange}>
      Created At
    </TableSortLabel>,
    <TableSortLabel active={['accessAsc', 'accessDesc'].includes(sortKey)} direction={sortKey === 'accessAsc' ? 'asc' : 'desc'} onClick={handleAccessSortKeyChange}>
      Last Access
    </TableSortLabel>,
    'Change Role',
    'ID',
  ]

  return (
    <React.Fragment>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex' }}>
          <h1>Users</h1>
          <IconButton onClick={() => setRefresh(!refresh)}>
            <Refresh />
          </IconButton>
        </div>
        {currentUser?.gatewayRole === 'admin' && (
          <Button
            variant="contained"
            color="primary"
            style={{ marginTop: 'auto', marginBottom: 'auto' }}
            onClick={() => setOpenMassRegisterDialog(true)}
          >
            <Add /> <strong>Add Users</strong>
          </Button>
        )}
      </div>

      {/* filters */}
      <Paper className={classes.filter}>
        <Typography variant="h6">
          <strong>Filters</strong>
        </Typography>

        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {/* don't display roles filter in dialog */}

          <FormControl className={classes.rolesFormControl}>
            <InputLabel id="roles-filter-label">Roles</InputLabel>
            <Select
              labelId="roles-filter-label"
              id="roles-filter"
              value={role}
              // multiple
              onChange={(e: any) => {
                setRole(e.target.value)
                setPage(1)
              }}
              //   renderValue={(selected: any) => selected.join(', ')}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {api.Roles.map((role: string) => (
                <MenuItem key={role} value={role}>
                  {role}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.groupsFormControl}>
            <InputLabel id="groups-filter-label">Groups</InputLabel>
            <Select
              labelId="groups-filter-label"
              id="groups-filter"
              value={groupVal}
              onChange={(e: any) => {
                setGroupVal(e.target.value)
                setPage(1)
              }}
            >
              <MenuItem value="">
                <em>All</em>
              </MenuItem>
              {groups.map((group: any) => (
                <MenuItem key={group.code} value={group.code}>
                  {group.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              label="Date Created-From"
              value={createFromVal}
              InputLabelProps={{ shrink: true }}
              inputProps={{ max: createToVal }}
              onChange={(e: any) => {
                setCreateFromVal(e.target.value)
                setPage(1)
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              type="date"
              label="Date Created-To"
              value={createToVal}
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: createFromVal }}
              onChange={(e: any) => {
                setCreateToVal(e.target.value)
                setPage(1)
              }}
            />
          </FormControl>
          <FormControl className={classes.formControl}>
            <TextField
              label="Search Users: Name or Email"
              style={{ width: 300 }}
              id="user-search"
              InputLabelProps={{ shrink: true }}
              onChange={lodash.debounce(
                (e: any) => {
                  setSearchKey(e.target.value)
                  setPage(1)
                },
                250,
                { maxWait: 1000 },
              )}
            />
          </FormControl>
        </div>
        <br />
        <Button
          onClick={handleReset}
          variant="outlined"
          className={classes.resetButton}
        >
          Reset
        </Button>
      </Paper>
      <br />
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-end',
        }}
      >
        <Checkbox
          checked={changeGroup}
          onChange={(e) => {
            setChangeGroup(e.target.checked)
            setIdsToChange([])
            setGroupToChange('')
            setRefresh(!refresh)
          }}
        />{' '}
        Change Users' Group
      </div>
      {changeGroup && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <FormControl className={classes.groupsFormControl}>
            <InputLabel id="groups-select-label">Groups</InputLabel>
            <Select
              labelId="groups-select-label"
              id="groups-select"
              value={groupToChange}
              onChange={(e: any) => setGroupToChange(e.target.value)}
            >
              {groups.map((group: any) => (
                <MenuItem key={group.code} value={group.code}>
                  {group.code}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            onClick={changeUsersGroup}
          >
            Change Group
          </Button>
        </div>
      )}
      <br />
      <Paper>
        <TableContainer style={{ maxHeight: 800 }}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {changeGroup && <TableCell />}
                {cols.map((col, index) => (
                  <TableCell key={index}>
                    <strong>{col}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <Row
                  user={user}
                  handleChangeRole={handleChangeRole}
                  key={user._id}
                  changeGroup={changeGroup}
                  idsToChange={idsToChange}
                  setIdsToChange={setIdsToChange}
                  currentUser={currentUser}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={total}
          rowsPerPage={limit}
          page={page - 1} // Material UI Table's pages start at 0, API's start at 1
          onChangePage={(event, newPage) => setPage(newPage + 1)}
          onChangeRowsPerPage={handleChangeLimit}
        />
      </Paper>
      {openMassRegisterDialog && (
        <MassRegisterDialog
          classes={classes}
          openMassRegisterDialog={openMassRegisterDialog}
          setOpenMassRegisterDialog={setOpenMassRegisterDialog}
          refresh={refresh}
          setRefresh={setRefresh}
          groups={groups}
          addToast={addToast}
        />
      )}
    </React.Fragment>
  )
}

function Row(props: any) {
  const [openChangeRoleDialog, setOpenChangeRoleDialog] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [roleToChange, setRoleToChange] = useState('')

  const { user, handleChangeRole, currentUser } = props

  // const currentUser = useContext(UserContext)

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const rowRenders: any = {
    name: (user: any) => processName(user.name),
    email: (user: any) => user.email,
    role: (user: any) => user.role.toUpperCase(),
    group: (user: any) => user.group,
    createdAt: (user: any) => new Date(new Date(user.createdAt).getTime() + 8*60*60000).toUTCString().replace("GMT", "SGT"),
    lastAccess: (user: any) => user.lastAccess ? new Date(new Date(user.lastAccess).getTime() + 8*60*60000).toUTCString().replace("GMT", "SGT") : "Not Found",
    changeRole: (user: any) => (
      <div>
        <Button
          variant="outlined"
          color="primary"
          aria-controls="assign-role"
          aria-haspopup="true"
          onClick={handleClick}
          size="small"
          disabled={currentUser ? currentUser.email === user.email : true}
        >
          Change Role
        </Button>
        <Menu
          id="assign-role-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {api.Roles.filter((role: string) => user.role !== role).map(
            (role: string) => (
              <MenuItem
                key={role}
                value={role}
                onClick={() => {
                  setOpenChangeRoleDialog(true)
                  handleClose()
                  setRoleToChange(role)
                }}
              >
                {role}
              </MenuItem>
            ),
          )}
        </Menu>
      </div>
    ),
    id: (user: any) => user._id,
  }

  const handleChangeRoleClick = () => {
    handleChangeRole(user, roleToChange)
    setOpenChangeRoleDialog(false)
  }

  return (
    <React.Fragment>
      <TableRow>
        {props.changeGroup && (
          <TableCell>
            <Checkbox
              checked={props.idsToChange.includes(props.user._id)}
              onChange={(e) => {
                e.target.checked
                  ? props.setIdsToChange([...props.idsToChange, props.user._id])
                  : props.setIdsToChange(
                      props.idsToChange.filter(
                        (id: string) => id !== props.user._id,
                      ),
                    )
              }}
            />
          </TableCell>
        )}
        {Object.keys(rowRenders).map((key) => (
          <TableCell key={key}>{rowRenders[key](props.user)}</TableCell>
        ))}
      </TableRow>
      {openChangeRoleDialog && (
        <ChangeRoleConfirmDialog
          openChangeRoleDialog={openChangeRoleDialog}
          setOpenChangeRoleDialog={setOpenChangeRoleDialog}
          handleChangeRoleClick={handleChangeRoleClick}
          user={props.user}
        />
      )}
    </React.Fragment>
  )
}

const ChangeRoleConfirmDialog = ({
  openChangeRoleDialog,
  setOpenChangeRoleDialog,
  handleChangeRoleClick,
  user,
}: any) => (
  <Dialog
    open={openChangeRoleDialog}
    onClose={() => setOpenChangeRoleDialog(false)}
  >
    <DialogTitle>Confirmation</DialogTitle>
    <DialogContent>
      Do you want to change the role for{' '}
      <strong>{processName(user.name) + ` (${user.email})`}</strong>?
      <div
        style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20 }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 5 }}
          onClick={handleChangeRoleClick}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{ margin: 5 }}
          onClick={() => setOpenChangeRoleDialog(false)}
        >
          No
        </Button>
      </div>
    </DialogContent>
  </Dialog>
)

const MassRegisterDialog = ({
  classes,
  openMassRegisterDialog,
  setOpenMassRegisterDialog,
  refresh,
  setRefresh,
  groups,
  addToast,
}: any) => {
  const [group, setGroup] = useState(groups[0].code)
  const [csv, setCsv] = useState(null as File | null)

  const handleSubmit = async () => {
    api.massRegister(csv as File, group)
    .then((res) => {
      setRefresh(!refresh)
      addToast('All Users registered successfully', {
        appearance: 'success',
      })
      setOpenMassRegisterDialog(false)
    })
    .catch((err) => {
      console.log(err)
      addToast(`Failed to register users: ${err.message}`, {
        appearance: 'error',
      })
      setOpenMassRegisterDialog(false)

    })
  }

  return (
    <Dialog
      open={openMassRegisterDialog}
      onClose={() => setOpenMassRegisterDialog(false)}
    >
      <DialogTitle>
        <strong>Mass Register Users</strong>
      </DialogTitle>
      <DialogContent>
        <React.Fragment>
          <strong>Sample CSV</strong>
          <Table size="small">
            <TableHead>
              <TableRow>
                {['email', 'name', 'expiry'].map((text, index) => (
                  <TableCell key={index}>
                    <strong>{text}</strong>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                {
                  email: 'sample1@sample.com',
                  name: 'Sample 1',
                  expiry: '2022-07-12',
                },
                {
                  email: 'sample2@sample.com',
                  name: 'Sample 2',
                  expiry: '2022-07-14',
                },
                {
                  email: 'sample3@sample.com',
                  name: 'Sample 3',
                  expiry: '2022-09-08',
                },
              ].map((u: any, index: number) => (
                <TableRow
                  style={{
                    backgroundColor: index % 2 ? '#fafafa' : '#eeeeee',
                  }}
                >
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {u.email}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {u.name}
                  </TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>
                    {u.expiry}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <br />
        </React.Fragment>
        <div style={{ display: 'flex', marginLeft: 7 }}>
          <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            <strong>CSV File: </strong>
          </p>
          <Dropzone
            onDrop={(acceptedFiles) => {
              setCsv(acceptedFiles[0])
            }}
            accept={['.csv']}
            maxFiles={1}
            noDrag
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <IconButton>
                    <InsertDriveFile />
                  </IconButton>
                </div>
              </section>
            )}
          </Dropzone>
          <p style={{ marginTop: 'auto', marginBottom: 'auto' }}>
            {csv && csv.name}
          </p>
        </div>
        <FormControl className={classes.groupsFormControl}>
          <InputLabel id="groups-select-label">Group</InputLabel>
          <Select
            labelId="groups-select-label"
            id="groups-select"
            value={group}
            onChange={(e: any) => {
              setGroup(e.target.value)
            }}
          >
            {groups.map((gr: any) => (
              <MenuItem key={gr.code} value={gr.code}>
                {gr.code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <br />
        <Button
          variant="contained"
          color="primary"
          style={{ float: 'right', marginTop: 10 }}
          onClick={handleSubmit}
          disabled={!Boolean(csv)}
        >
          Submit
        </Button>
      </DialogContent>
    </Dialog>
  )
}
